import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import useByBlogs from '../../hooks/useByBlogs';
import './blog.css';
import api from '../../api/api';

const Blogdetail = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const cookies = new Cookies();

    const appUrl = process.env.REACT_APP_API_URL;
    const appSubUrl = '/storage/';

    const setting = useSelector(state => state.setting);
    const filter = useSelector(state => state.productFilter);

    const { slug } = useParams();

    const [limit, setLimit] = useState(12);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const { data, totalData, loading } = useByBlogs(localStorage.getItem("jwt_token"), limit, offset, slug);

    const placeHolderImage = (e) => {

        e.target.src = setting.setting?.web_logo;
    };


    const handlePageChange = (pageNo) => {
        setCurrentPage(pageNo);
        setOffset(pageNo * limit - limit);
    };

    const placeholderItems = Array.from({ length: 12 }).map((_, index) => index);

    return (
        <>

            {loading ?
                <div className='row justify-content-center mx-3'>

                </div>
                :
                <section id="blogsdetail" className='blogsdetailhead'>
                    <div className="container">
                        <div className='row'>
                            {data?.map((ctg, index) => (
                                <div className="wrapper">
                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <h3>{ctg.title}</h3>
                                        <div className='textin'>
                                            <div className="card-banner">
                                                <img className="banner-img1" src={appUrl + appSubUrl + ctg.image} alt='' />
                                            </div>
                                            <div className="card-body blogspost-body1">
                                                <div className="blog-description" dangerouslySetInnerHTML={{ __html: ctg.description }}></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            }
        </>
    )

}
export default Blogdetail;