import React, { useState, useEffect, useRef } from 'react';
import './footer.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api/api';
import { ActionTypes } from '../../model/action-type';
import Loader from '../loader/Loader';
import paystack_svg from '../../utils/ic_paystack.svg';
import paypal_svg from '../../utils/ic_paypal.svg';
import paytm_svg from '../../utils/ic_paytm.svg';
import cod_svg from '../../utils/ic_cod.svg';
import razorpay_svg from '../../utils/ic_razorpay.svg';
import stripe_svg from '../../utils/ic_stripe.svg';
import certi1 from '../../utils/image/certi1.png';
import certi2 from '../../utils/image/certi2.png';
import certi3 from '../../utils/image/certi3.png';
import google from '../../utils/image/google.png';
import { setFilterSearch } from "../../model/reducer/productFilterReducer";
import { setFilterBrands } from '../../model/reducer/productFilterReducer';
import { setFilterMinMaxPrice } from '../../model/reducer/productFilterReducer';
import { useTranslation } from 'react-i18next';
import { setCategory } from '../../model/reducer/categoryReducer';
import { setFilterCategory } from '../../model/reducer/productFilterReducer';
import truck from '../../utils/image/delivery-truck.png'
import updown from '../../utils/image/up-and-down-arrows.png'
import customerService from '../../utils/image/customer-service.png'
import moneyBack from '../../utils/image/money-back.png'
import vector from '../../utils/image/Vector.png'
import image22 from '../../utils/image/image 22.png'
import image23 from '../../utils/image/image 23.png'
import image24 from '../../utils/image/image 24.png'
import image25 from '../../utils/image/image 25.png'
import FooterBrand from '../brands/FooterBrand';
import contacticon from '../../utils/image/contact.png';
import whatsappicon from '../../utils/image/whatsapp.png';
import envelope from '../../utils/image/envelope.png';
import ccavenue from '../../utils/ic_ccavenue.png';
export const Footer = () => {

	const searchNavTrigger = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const setting = useSelector(state => (state.setting));
    const curr_url = useLocation();
    const { t } = useTranslation();
  
    const fetchCategory = (id = 0) => {
        api.getCategory(id)
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    dispatch(setCategory({ data: result.data }));
                    // dispatch({ type: ActionTypes.SET_CATEGORY, payload: result.data });
                }
            })
            .catch(error => console.log("error ", error));
    };


    useEffect(() => {
        fetchCategory();
    }, []);

    const category = useSelector((state) => (state.category));

    const selectCategory = (ctg) => {
        if (ctg.has_child) {

            fetchCategory(ctg.id);
            navigate('/products');
        } else {
            dispatch(setFilterCategory({ data: ctg.id }));
            // dispatch({ type: ActionTypes.SET_FILTER_CATEGORY, payload: ctg.id });
            navigate('/products');

        }
    };

    return (<>
        <div>
            <FooterBrand />
        </div>

        {/* <div className="container">
            <div className="row align-items-center newsletter">
                <div className="col-lg-4 col-sm-12 d-flex justify-content-center align-items-center text-center">
                    <div style={{ background: 'white', borderRadius: '50%', padding: "10px" }}>
                        <img src={vector} alt="Image 1" />
                    </div>
                    <p className="big-font p-4" style={{ color: "white" }}>Sign up newsletter</p>
                </div>
                <div className="col-lg-8 col-sm-12 p-4">
                    <div className="d-flex justify-content-center align-items-center">
                        <input type="text" placeholder='Your email address' className="newsletter-input" style={{ width: '100%', padding: '13px' }} />
                        <div className="input-group-append p-4">
                            <button className="newsletter-btn" type="button">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="container-fluid">
            <div className="row p-3 backgroundfooter">
                <div className="col-lg-3 col-sm-3 footerimages">
                    <img src={truck} alt="" />
                    <p className="big-font">Fast Delivery</p>
                </div>
                <div className="col-lg-3 col-sm-3 footerimages">
                    <img src={updown} alt="" />
                    <p className="big-font">Exchange Offer</p>
                </div>
                <div className="col-lg-3 col-sm-3 footerimages">
                    <img src={customerService} alt="" />
                    <p className="big-font">24 * 7 Support</p>
                </div>
                <div className="col-lg-3 col-sm-3 footerimages">
                    <img src={moneyBack} alt="" />
                    <p className="big-font">Money Back</p>
                </div>
                {/* <div className="col-lg-2 col-sm-6 footerimages">
                    <img src={moneyBack} alt="" />
                    <p className="big-font">Money Back</p>
                </div> */}
            </div>
        </div>
        <section  id="footer">
            <div className="container pb-3">
                <div className="row justify-content-evenly"> 

                    <div className="col-xs-2 col-sm-6 col-lg-2 col-md-6 col-12">
                        <h5>About Us</h5>
                        <ul className="link-list" onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                            <li><Link to={'/about'}>Our Story</Link></li>
                            <li><Link to={'/about'}>Mission & Values</Link></li>
                            <li><Link to={'/about'}>Careers</Link></li>
                            <li><Link to={'/about'}>Press</Link></li> 
                        </ul>
                    </div>



                    <div className="col-xs-2 col-sm-6 col-lg-2 col-md-6 col-12">
                        <h5>Services & Policies</h5>
                        <ul className="link-list" onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }}> 
                            <li><Link to={'/contact'}>{t("contact_us")}</Link></li>
                            <li><Link to={'/faq'}>{t("faq")}</Link></li>
                            <li><Link to={'/policy/Shipping_Policy'}>{t("shipping_policy")}</Link></li>
                            <li><Link to={'/policy/ReturnsAndExchangesPolicy'}>{t("recharge_and_exchange_policy")}</Link></li> 
                            <li><Link to={'/terms'}>{t("terms_and_conditions")}</Link></li>
                            <li><Link to={'/policy/Privacy_Policy'}>{t("privacy_policy")}</Link></li> 
                            <li><Link to={'/policy/Cancellation_Policy'}>{t("cancellation_policy")}</Link></li>
                            <li><Link to={'/categoryFeeCalculation'}>{t("Category Fee Calculation")}</Link></li>  
                        </ul>
                    </div>
                    <div className="col-xs-2 col-sm-6 col-lg-2 col-md-6 col-12">
                        <h5>Shop</h5>

                        <ul className="link-list" onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                            <li><Link
							to={'/products'}
							onClick={(e) => {
								  dispatch(setFilterSearch({ data: null }));
								 dispatch(setFilterCategory({ data: null }));
								dispatch(setFilterBrands({ data: [] }));
								dispatch(setFilterMinMaxPrice({ data: null }));
								searchNavTrigger.current.click();
								navigate('/products');
							}}
							//to={'/products'}
							>Browse Products</Link></li>
                            <li><Link to={'category/all'}>Categories</Link></li>
                            <li><Link to={'/brands'}>Brands</Link></li>
                            <li><Link to={'/products'}>Gift Card</Link></li> 
                            <li><Link to={'/contact'}>Newsletter</Link></li> 
                            <li><Link to={'/blogs'}>Blogs</Link></li>
                        </ul>

 

                    </div>
                    
                    <div className="col-xs-3 col-sm-6 col-md-6 col-lg-2 col-12 d-flex flex-column justify-content-between">

                     
                        <div> 

                            {setting.setting ? <>
                        <div className="download_desc d-flex flex-column justify-content-start">
                            {/* <p>{setting.setting.web_settings.app_short_description}</p> */}
                            <Link to='/' className='site-brand' style={curr_url.pathname === '/profile' ? { marginLeft: '4px' } : null}>
                                <img src={setting.setting && setting.setting.web_settings.web_logo} width="90px" alt="logo" className='desktop-logo hide-mobile' />
                                <img src={setting.setting && setting.setting.web_settings.web_logo} width="80px" alt="logo" className='mobile-logo hide-desktop' />
                            </Link>
                            <div className='footer-logo1 mt-4'>
                                Security Certification
                            </div>
                            <div className='d-flex mt-4'>
                                <img className='px-4' src={certi1} alt="certificate" />
                                <img className='px-4' src={certi2} alt="certificate" />
                                <img className='px-4' src={certi2} alt="certificate" />
                            </div>
                            
                            <div className='d-flex mt-5'>
                                <span className='supportTxt mt-2' >Click For Support</span>
                                <a href={`//api.whatsapp.com/send?phone=+91-6377100162&amp;text=Hello${setting.setting !== null ? setting.setting.support_number : "number"}&text=Hello!`} className='socical-icons'>
                                  <img src={whatsappicon} alt="contactic" style={{height:"25px"}}/>
                                </a>

                                <a href={`tel:${setting.setting !== null ? setting.setting.support_number : "number"}`} className='socical-icons'>
                                    <img src={contacticon} alt="contactic" style={{height:"25px"}}/>
                                </a>

                                <a href={`mailto:${setting.setting !== null ? setting.setting.support_email : "email"}`} className='socical-icons'>
                                  <img src={envelope} alt="contactic" style={{height:"25px"}}/>
                                </a>
 
                            </div>


                        </div>
                    </> : <></>}

                             
                        </div>
                        <div className='d-flex flex-column  justify-content-start mt-3 mb-3'>
                            <div style={{ color: "white", marginBottom: "5px" }}>
                            {setting.setting && setting.setting.web_settings.app_title}
                            </div>
                            <div className='gap-3 d-flex justify-content-start'>
                            {setting.setting ? <> 
                                    <div className='gap-3 d-flex justify-content-start'>
                                        {setting.setting.web_settings.is_android_app === "1" ?
                                            <a href={setting.setting.web_settings.android_app_url} className='download-button'>
                                                <img src={setting.setting.web_settings.play_store_logo} alt='google-play'></img>
                                            </a>
                                            : <></>}
                                        {setting.setting.web_settings.is_ios_app === "1" ?
                                            <a href={setting.setting.web_settings.ios_app_url} className='download-button'>
                                                <img src={setting.setting.web_settings.ios_store_logo} alt='google-play'></img>
                                            </a>
                                            : <></>} 
                                    </div>
                                </>
                                    : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer ">
                <div className="container flex-sm-row flex-column  bottom-section-footer gap-5">
                    {setting.setting && setting.setting.social_media.length > 0 ?
                        <div className="social-media-icons order-sm-0" key={"icons"} >
                            <span>{t('follow_us')}:

                                {setting.setting.social_media.map((icon, index) => {
                                    return (
                                        <a key={index} href={icon.link} className='socical-icons' target="_blank">
                                            <i className={`${icon.icon} fa-lg`} style={{ color: "#fff" }}>
                                            </i>
                                        </a>
                                    );
                                })}
                            </span>
                        </div>
                        : null}
                    <div className="copyright order-sm-1 order-2">
                        <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
                            <span className='company_name'>{setting.setting !== null ? setting.setting.web_settings.copyright_details : "App Name"}</span>
                        </div>
                    </div>
                    {setting.payment_setting ?
                        <div className="payment_methods_container order-sm-1">
                            {setting.payment_setting.cod_payment_method === "1" ?
                                <span className='payment_methods'>
                                    <img src={cod_svg} alt="" srcSet="" />
                                </span>
                                : <></>}
                            {setting.payment_setting.paystack_payment_method === "1" ?
                                <span className='payment_methods'>
                                    <img src={paystack_svg} alt="" srcSet="" />
                                </span>
                                : <></>}
                            {setting.payment_setting.paypal_payment_method === "1" ?
                                <span className='payment_methods'>
                                    <img src={paypal_svg} alt="" srcSet="" />
                                </span>
                                : <></>}
                            {setting.payment_setting.stripe_payment_method === "1" ?
                                <span className='payment_methods'>
                                    <img src={stripe_svg} alt="" srcSet="" />
                                </span>
                                : <></>}
                            {/* {setting.payment_setting.paytm_payment_method === "1" ?
                                <span className='payment_methods'>
                                    <img src={paytm_svg} alt="" srcSet="" />
                                </span>
                                : <></>} */}
                            {setting.payment_setting.razorpay_payment_method === "1" ?
                                <span className='payment_methods'>
                                    <img src={razorpay_svg} alt="" srcSet="" />
                                </span>
                                : <></>}
								
							    {setting.payment_setting.ccavenue_payment_method === "1" ?
                                <span className='payment_methods'>
                                    <img src={ccavenue} alt="" srcSet="" />
                                </span>
                                : <></>}	
                        </div>
                        : <></>}
                </div>
            </div>
        </section >

        
    </>
    );
};