import React, {useState, useEffect} from 'react'
import './landingpage.css';
import logoimage from '../../utils/image/logoimageland.png';
import landimg from '../../utils/image/landsideimg.png';
import stepone from '../../utils/image/screenshothome.png';
import steptwo from '../../utils/image/step2screenshot.png';
import stepthree from '../../utils/image/screenshotseller.png';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector } from 'react-redux';
import { RiAccountCircleFill } from "react-icons/ri";
import Login from '../login/Login';
import api from '../../api/api';
import { setCity } from "../../model/reducer/locationReducer";
import { useTranslation } from 'react-i18next';
const Landingpage = () => {
    const user = useSelector(state => (state.user));
    const city = useSelector(state => (state.city));
    const [isLocationPresent, setisLocationPresent] = useState(false);
    const setting = useSelector(state => (state.setting));
    const dispatch = useDispatch();
    const [locModal, setLocModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        if (setting.setting?.default_city && city.city == null) {
            setisLocationPresent(true);
            api.getCity(parseFloat(setting.setting.default_city?.latitude), parseFloat(setting.setting.default_city?.longitude))
                .then(response => response.json())
                .then(result => {
                    if (result.status === 1) {
                        dispatch(setCity({ data: result.data }));
                    }
                });
            setisLocationPresent(true);
        }
        else if (setting?.setting && setting.setting?.default_city == null && city?.city == null) {
            setLocModal(true);
        }
    }, [setting]);
    const placeHolderImage = (e) => {
        e.target.src = setting.setting?.web_logo;
    };

  return (
    <>
          <section className='landingpageseller'>
              <div className=''>
                  <nav className="navbar navbar-expand-lg">
                      <div className="container">
                          <div className="collapse navbar-collapse" id="navbarSupportedContent">
                              <div className="d-flex w-100 justify-content-end">
                                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                      <li className="nav-item p-2">
                                          <a className="nav-link" aria-current="page" href="/">Home</a>
                                      </li>
                                      <li className="nav-item p-2">
                                          <a className="nav-link" href="/blogs">Blogs</a>
                                      </li>
                                      <li className="nav-item p-2">
                                          <a className="nav-link" href="/contact">Contact</a>
                                      </li>
                                    <li>
                                      {user.status === 'loading'
                                    ? (
                                        <div className='hide-mobile-screen'>
											 <Link to={'https://admin.myacebazar.com/seller/login'} className='d-flex align-items-center flex-row user-profile gap-1' >
                                            <div whiletap={{ scale: 0.6 }} className='d-flex flex-row user-profile1 gap-1 pt-0' onClick={() => setShowModal(true)}>
                                                <div className='d-flex align-items-center user-info my-auto'>
                                                    <span className='btn-success'><RiAccountCircleFill className='user_icon' /></span>
                                                    <span className='pe-3 loginclassland'>{t("login")}</span>
                                                </div>
                                            </div>
											 </Link>
                                        </div>
                                    )
                                    : (
                                        <div className='hide-mobile-screen ms-4'>
                                            <Link to='/profile' className='d-flex align-items-center flex-row user-profile gap-1' >
                                            <img onError={placeHolderImage} src={user.user && user.user.profile} alt="user"></img>
                                                <div className='d-flex flex-column user-info my-auto'>
                                                    <span className='number'> {t("welcome")}</span>
                                                    <span className='name'> {user.user && user.user.name.split(' ')[0]}</span>
                                                </div>
                                            </Link>
                                        </div>
                                    )}
                                    </li>


                                  </ul>
                              </div>
                          </div>
                      </div>
                  </nav>
              </div>
          </section>

    <section className='title1'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-7 col-md-7 col-sm-7'>
                    <div className='logohead'>
                        <img src={logoimage} alt=''/>
                    </div>
                    <div className='textinfo'>
                        <p>भारत 
                            <span className='span1'> का सर्वश्रेष्ठ</span>
                            <span className='span2'> Local to Global</span>
                            <span className='span3'> E बाजार</span>
                        </p>
                        <h3>BECOME A MY ACE BAZAR SELLER</h3>
                        <h4>Start your selling journey on My Ace Bazar</h4>
                    </div>
                    <div className='sellerbutton'>
                        <Link to={'https://admin.myacebazar.com/seller/register'}><button className='btn-lg'>start selling</button></Link>
                    </div>
                </div>
                <div className='col-lg-5 col-md-5 col-sm-5'>
                    <div className='sidelandimg'>
                        <img src={landimg} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='overview'>
        <div className='container'>
            <h2>Overview</h2>
            <h3>Why Sellers Choose My Ace Bazar?</h3>
            <div className='row'>
                <div className='col-lg-4 col-md-4 col-sm-4 borderRight'>
                    <div className="overviewdesc">
                        <h4>Sell Across India</h4>
                        <p>Expand your market reach with ease and efficiency by selling acrossIndia. Our platform connects you with a diverse customer basenationwide, offering a seamless experience from listing to delivery.Benefit from comprehensive logistics support, secure payment options,and targeted marketing strategies tailored to regional preferences. Growyour business by tapping into India's dynamic and vast market. Startselling across India today and watch your business thrive!</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4 borderRight'>
                    <div className="overviewdesc">
                        <h4>Fast & Regular Payments with My Ace Bazar</h4>
                        <p>Experience seamless financial management with My Ace Bazar's fast andregular payments. Our ecommerce portal ensures timely and consistentpayouts, so you can maintain smooth cash flow and focus on growingyour business. Trust My Ace Bazar for reliable and efficient paymentprocessing.</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4'>
                    <div className="overviewdesc">
                        <h4>Unbeatable Reach in Online Business</h4>
                        <p>Unlock unparalleled market potential with My Ace Bazar. Our platformprovides you with an extensive reach across diverse customer segments,ensuring your products are seen by a wide audience. Benefit from ouradvanced marketing tools and broad network to maximize visibility anddrive sales. Elevate your online business and connect with morecustomers than ever before with My Ace Bazar.</p>
                    </div>
                </div>
            </div>
            <div className='row bordertop'>
                <div className='col-lg-4 col-md-4 col-sm-4 borderRight'>
                    <div className="overviewdesc1">
                        <h4>Dedicated Account Manager</h4>
                        <p>Experience personalized support with My Ace Bazar’s dedicated accountmanagement. Our expert team is here to assist you every step of the way,from optimizing your store to resolving any issues swiftly. Enjoy tailoredguidance and solutions that cater to your unique business needs,ensuring a smooth and successful ecommerce experience. Partner withus for exceptional support and focus on growing your business withconfidence</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4 borderRight'>
                    <div className="overviewdesc1">
                        <h4>Easy of starting online business</h4>
                        <p>Kickstart your online business effortlessly with My Ace Bazar. Ourplatform offers a straightforward setup process, user-friendly platform,and comprehensive support to get you up and running quickly. Fromcreating your store to managing sales, everything is designed forsimplicity and efficiency. Start your online business with My Ace Bazartoday and focus on what you do best—growing your brand.</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4'>
                    <div className="overviewdesc1">
                        <h4>Simple Pricing Calculator</h4>
                        <p>Easily manage your costs with My Ace Bazar’s intuitive pricing calculator.Our tool simplifies pricing decisions by providing clear, straightforwardcalculations, so you can set competitive rates and maximize profits withconfidence. Streamline your pricing strategy effortlessly and focus ongrowing your business with My Ace Bazar.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='stepstoregister'>
        <div className='container'>
            <h2>How to Register on My Ace Bazar?</h2>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-6'>
                    <div className='stepsimg text-center'>
                        <h3>Step 1</h3>
                        <p>Go to (link) and click on https://www.myacebazar.com</p>
                        <img src={stepone} alt='' style={{height:'408px', paddingBottom:'20px'}} className='steponeimage'/>
                        <h3>Step 2</h3>
                        <p>Visit the official My Ace Bazar website and click on “Become a Seller”.</p>
                        <img src={steptwo} alt="" style={{height:'66px'}} className='steptwoimage'/>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6'>
                    <div className='stepsimgseller text-center'>
                        <h3>Step 3</h3>
                        <p>Fill out this form for Registration</p>
                        <img src={stepthree} alt="" style={{height:'408px'}}/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
export default Landingpage;
