import React, { useEffect, useState } from "react";
import "./suggestion.css";
import axios from "axios";

const Suggestion = ({
  search,
  setsearch,
  suggestions,
  setSuggestions,
  data,
  setData,
  setFilterSearch,
  setFilterCategory,
  setFilterBrands,
  setFilterMinMaxPrice,
  dispatch,
  searchNavTrigger,
  navigate,
  curr_url,
  setShowSuggetion,
  keyCheck,
  setKeyCheck,
  setKeyCheckArr


}) => {
  const filteredSuggestions = (data || []).filter((item) => {
    return (
      typeof item === "string" &&
      item.toLowerCase().includes(search.toLowerCase())
    );
  });

  useEffect(() => {
    setKeyCheckArr(filteredSuggestions);
  }, [filteredSuggestions, setKeyCheckArr]);

  const clickHandler = (item) => {
    setsearch(item);

    setSuggestions(false);
    if (search !== "") {
      dispatch(setFilterSearch({ data: search }));
      dispatch(setFilterCategory({ data: null }));
      dispatch(setFilterBrands({ data: [] }));
      dispatch(setFilterMinMaxPrice({ data: null }));
      searchNavTrigger.current.click();
    }
  };
  return (
    <div className="suggestion_main" onClick={() => {
      setTimeout(() => {
        setKeyCheck(-1)
        setShowSuggetion(false)
      }, 0)

    }}>
      {filteredSuggestions.slice(0, 6).map((item, index) => (
        <div
          key={index}
          className={`suggestion_child ${keyCheck === index ? 'highlight' : ''}`}
          onClick={() => clickHandler(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default Suggestion;
